<template>
  <add-role-form
      :abilities="generalAbilities"
      :associated-abilities="associatedAbilities"
      :is-associated-section="!isAdmin()"
      :is-loading="isLoading"
      :repository="repository"
      @refresh-data="$router.replace({name: 'roles'})"
  />
</template>

<script>
import AddRoleForm from '@/components/roles/form.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    AddRoleForm,
  },
  data() {
    return {
      generalAbilities: {},
      associatedAbilities: {},
      isLoading: false,
      repository: RepositoryFactory.create('roleRepo'),
    }
  },
  async created() {
    this.isLoading = true
    await this.getUtilsPermissions()
    this.isLoading = false
  },
  methods: {
    async getUtilsPermissions() {
      await this.utilsAppRepository().getPermissions()
          .then(res => {
            this.generalAbilities = res.data.data.general
            this.associatedAbilities = res.data.data.associated
          })
    },
  }
}
</script>
